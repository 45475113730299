import { format, isPast, isToday, isTomorrow, sub } from 'date-fns';
import { sortBy } from 'lodash';
import { EPathname } from '../routes/index';

// UI related constants
export const background = ['IT-Coding', 'IT-Non Coding', 'Non-IT'];
export const degree = ['B.Tech/BE', 'BSC', 'BCA', 'B.Com/BBA/BA', 'Other'];
export const program = ['Software Development', 'QA Automation'];
export const profiles = ['Yes', 'No'];
export const EProgramInterested = [
  'Software Development',
  'QA Automation',
  'Data Analytics',
];

export const programNameTrialTypeIdMapping = {
  'Software Development': 'FELLOWSHIP_TRIAL',
  'QA Automation': 'MQA_TRIAL',
  'Data Analytics': 'DATA_SCIENCE',
};

export const employmentStatus = [
  'Student',
  'Working Professional',
  'Non-working Graduate',
];
export const highestQualifications = [
  'B.Tech/BE',
  'BSc',
  'BCA',
  'B.Com/BBA/BA',
  'MBA',
  'M.Tech',
  'M.Com/MA',
];
export const jobDomains = ['Tech', 'Non Tech'];
export const jobRoleTechs = [
  'Software Development Engineer (SDE) Frontend',
  'Software Development Engineer (SDE) Backend',
  'Software Development Engineer (SDE) Full Stack',
  'Software Development Engineer (SDE) Generalist',
  'Data Scientist',
  'Devops Engineer',
  'iOS Engineer',
  'Android Engineer',
  'Production Support',
  'System Administrator',
  'Database Administrator',
  'Product Implementation (SAP, Salesforce etc)',
  'Data Analyst',
  'Business Analyst',
  'QA Engineer',
];
export const workExperience = ['NA', '<1', '1-3', '3-5', '>5'];
export const ctc = ['NA', '1-3', '3-5', '5-9', '9-15', '15-25', '25-35', '35+'];
export const projectCount = ['None', '1-2', '3-5', '5+'];
export const jobRoleNonTechs = [
  'Marketing',
  'Sales',
  'Operations',
  'Customer Success',
  'Project Management',
  'Leadership',
  'Product Management',
];
export const workExperiences = [
  '0-1 years',
  '1-2 years',
  '2-5 years',
  '5+ years',
];
export const companies = [
  'Tcs',
  'Cognizant',
  'Infosys',
  'Accenture',
  'Capgemini',
  'Wipro',
  'Hcl Tech',
  'Tech Mahindra',
  'Amazon',
  'Byjus',
  'Ibm',
  'Deloitte',
  'Bigsteptech',
  'Ltimindtree',
  'Self Employed',
  'Amdocs',
  'Cybage Software',
  'Dxc Technology',
  'Fujitsu',
  'Mindtree',
  'Nagarro',
  'Skill-Lync',
  'Virtusa',
  'Wns',
  'Accolite Digital',
  'Adp',
  'Altiostar',
  'Coforge',
  'Concentrix',
  'Cts',
  'E Web Digital',
  'Fis',
  'Hp',
  'Intellect Design Arena',
  'L&T',
  'Lti',
  'National Payment Corporation Of India',
  'Persistent Systems',
  'Php Developer',
  'Pwc',
  'Sales',
  'Sherweb',
  'Sopra Steria',
  'System Engineer',
  'Tata 1Mg',
  'Teleperformance',
  'Vmware',
  '.Net Web Developer',
  '10Xripple',
  '12Th Wonder Research India Pvt Ltd',
  '18North Systems Pvt Ltd',
  '2Coms Consulting Pvt Ltd',
  '360 Degree Cloud Tech',
  '75Way Technologies',
  '98Thpercentile',
  'Acidaes Solution Pvt. Lmt. (Crm Next)',
  'Acronotics Private Limited',
  'Act',
  'Acuver Consulting Pvt Ltd',
  'Adiyogi Technosoft Pvt.Ltd.',
  'Admedia Digital Labs Pvt Ltd',
  'Advanced Voice Research Labs (Avrl)',
  'Affimintus Technology Pvt Ltd',
  'Agbe Technologies',
  'Ags Health Pvt Ltd',
  'Ahex Technology',
  'Airtel Digital',
  'Akrivia Automation Private Limited',
  'Allianz Technology Hungary',
  'Alphatech Software ( Saviom Software)',
  'Altair Engineering',
  'Altimetrik India Pvt Ltd',
  'Altiostar Networks Pvt Ltd',
  'Amazon Development Centre India Pvt Ltd',
  'Ampmesh Labs Pvt Ltd',
  'Anacon Labs Pvt Ltd',
  'Analyst',
  'Andhra Pradesh Grameena Vikas Bank',
  'Ankit Narwade',
  'Anteriad',
  'Anthony Nazareth',
  'Apisero',
  'Applore',
  'Appventurez Mobitech Pvt Ltd.',
  'Aptean',
  'Aqm Technologies',
  'Aqm Technology Pvt Ltd',
  'Arch Enterprises',
  'Arm',
  'Armundia Group Srl',
  'Artech Infosystem Pvt Ltd',
  'Asian Paints',
  'Ass Professor',
  'Associate Software',
  'Ast',
  'Autonomo',
  'Avcon Controls Pvt. Ltd.',
  'Axis Bank',
  'Backend Developer',
  'Baker Hughes Company',
  'Bancbridge Software Solutions',
  'Baniwal Infotech',
  'Bank Of America',
  'Basilroot Technologies Pvt Ltd',
  'Bda',
  'Believers Church Medical College Hospital',
  'Bharat Institute Of Technology Meerut',
  'Bharti Soft Tech Pvt Ltd',
  'Biofusion Therapeutics',
  'Biosoul Foods And Beverages Pvt Ltd',
  'Birlasoft',
  'Bizkonnect Solutions Pvt Ltd',
  'Bmc Software',
  'Bny Mellon',
  'Boghala Viswanatha Reddy',
  'Bosch Global Software Technologies',
  'Bosleo',
  'Bosleo Technology Pvt Ltd',
  'Bramhansh Technologies',
  'Brightly Software- A Siemens Company',
  'Bristlecone',
  'Bt - Eserve',
  'Business One Technologies',
  'Bytedance Technology India Pvt Ltd',
  'Caliber Mercantile Pvt Ltd',
  'Canara Bank',
  'Capillary Technology',
  'Careti',
  'Cargotec',
  'Carl Zeiss India(Bangalore) Pvt. Ltd.',
  'Catalyst Business Solutions',
  'Cedcoss Technology',
  'Cedge Technologies',
  'Cellde Innovation Labs Private Limited',
  'Cerner',
  'Cerner Corporation',
  'Cerulean Infotech',
  'Chaser Technologies',
  'Chegg India Pvt Ltd',
  'Cigniti Technologies Limited',
  'Cisco Systems Ltd.',
  'Cispl',
  'Citi Bank',
  'Citrix',
  'City Union Bank',
  'Clavax Technologies',
  'Cleonix Technologies Private Limited',
  'Cloud Engineer',
  'Cloudthat',
  'Cmacgm',
  'Codetronix Software Solutions',
  'Coforge Ltd',
  'Cogent Infotech',
  'Coin Earth Technologies Pvt Ltd',
  'Commerce',
  'Commerce Pundit Technologies Pvt Ltd',
  'Commerceiq',
  'Commit Technologies Pvt.Ltd',
  'Congruex Asia Pasific Llp',
  'Connectwise',
  'Construction',
  'Contentstack India Private Ltd.',
  'Continental Automotive',
  'Cotiviti',
  'Credable',
  'Crest Infosystems',
  'Crio',
  'Cs Datamation Research Services Pvt Ltd',
  'Cs Soft Solutions',
  'Cummins',
  'Currencies Direct',
  'Cvent',
  'Dariohealth',
  'Darwinbox Digital Solutions Pvt.Ltd',
  'Dashclicks India Pvt. Ltd.',
  'Data Fusion',
  'Dav Public School',
  'Dbs',
  'Decision Culture',
  'Dell',
  'Design Imperial',
  'Design Shore',
  'Designcafe',
  'Deutsche Telekom Digital Labs',
  'Devops Associate',
  'Dexra Solutions',
  'Dhara Associates Architects And Engineers',
  'Divum Corporate Services',
  'Docking Station Technologies Pvt Ltd',
  'Doddigari Shyam',
  'Dtdc Courier',
  'Dzealco Llp',
  'E-Smart',
  'Eagleburgmann',
  'Eastern Software Services Pvt Ltd',
  'Easyrewardz Software Services',
  'Ebix',
  'Ecs Bussiness Software Solutions',
  'Edesk',
  'Edu Plus Campus',
  'Ee',
  'Electrokings',
  'Eluminous Technologies',
  'Emtec',
  'Endurance',
  'Engineering',
  'Eniacworld',
  'Epam',
  'Epam Systems',
  'Eprocess International Ghana Limited',
  'Eq Technologic',
  'Ernst & Young Llp',
  'Espire Infolabs',
  'Essilor India Pvt Ltd',
  'Ethica Invest (Self Employed)',
  'Evc Ventures (Unifyed)',
  'Exterro R And D Pvt Ltd',
  'Fa Software Private Limited Chennai',
  'Faiz Anwar',
  'Firsthub',
  'Fiserv',
  'Fleet Management Limited',
  'Fleet Studio',
  'Food Safaty Service, Nagpur',
  'Foreign Tech Pvt Ltd',
  'Freelance',
  'Freelancer',
  'Freelancing',
  'Freshworks',
  'Freyrsoftware Service Pvt Ltd Ltd',
  'Front End Developer',
  'Full Time',
  'Gallagher (Process Associate)',
  'Geekyants Pvt Ltd',
  'Genpact',
  'Ghaziz L.L.C.',
  'Globalfoundries',
  'Globallogic',
  'Globalogical Technology Company',
  'Glogic',
  'Goalsr Pvt Ltd',
  'Golden Source',
  'Goldensource',
  'Good Glamm Group',
  'Google',
  'Government',
  'Government Of Karnataka',
  'Govt',
  'Granules India Limited',
  'Great Eastern Energy Corporation Ltd.',
  'Greenmines Organic Foods India Pvt Ltd',
  'Groot Construction',
  'Growthlo Lab Pvt Ltd New Delhi',
  'Guidehouse',
  'Habilelabs Pvt. Ltd.',
  'Happiestminds',
  'Harini',
  'Hashmap Inc (An Ntt Data Company)',
  'Healthcare At Home Pvt Ltd',
  'Hindustan Coca-Cola Beverages Pvt Ltd',
  'Hitachi',
  'Hotel Express Inn',
  'Hsbc',
  'Htc Global Services',
  'Hudson It',
  'I Am Working As A Freelancer',
  "I Don'T Want To",
  'I-Trust Informatics',
  'Ice Data Services',
  'Icici Prudential',
  'Ics',
  'Idfc First Bank',
  'Idrive Software India Private Limited',
  'Ienergizer Pvt Ltd',
  'Iiim Ltd',
  'Iist',
  'Imerit Technology Pvt Ltd',
  'Impelsys',
  'Imran',
  'Inceptory Technology Private Limited',
  'Indiamart Intermesh Ltd.',
  'Indium',
  'Infinite',
  'Infinx',
  'Infinx Service Pvt Ltd',
  'Infodeal Technologies Pvt Ltd',
  'Infogain India Pvt Ltd',
  'Infoobjects Software Pvt Ltd',
  'Infotech',
  'Infy',
  'Injala Inc',
  'Innominds Software',
  'Innovative Digitech Service',
  'Innroad',
  'Integreon',
  'Intellect',
  'Intellect Design Arena Pvt Ltd',
  'Intelligent Studios Pvt Ltd',
  'Intellihealth Solution Pvt Ltd',
  'Interactive Brokers',
  'Interglobe Aviation Ltd',
  'Interra Systems Pvt. Ltd.',
  'Intership',
  'Iqvia',
  'Irdeto',
  'Iris Software Technologies Pvt Ltd',
  'It Infotech Pvt. Ltd. Bhopal',
  'It Networkz Infosystems Pvt Ltd',
  'Itech Pro',
  'Ivy Comptech',
  'J Ray Mcdermott',
  'Jadhao Layland Pvt.Ltd',
  'Jafar Sadiq',
  'Jainam Software Llp',
  'Jamna Auto Ltd',
  'Jaya Dubey',
  'Jetlearn',
  'Jhonson Control',
  'Jld Pvt Ltd',
  'Jsw One Platforms',
  'Jungleworks',
  'Jyoti Polymers',
  'K Geetha Maheswari',
  'Kaamwork',
  'Kabra Extrusiontechnik',
  'Kalayanamalai',
  'Kangla Aviation',
  'Karexpert Technologies Pvt. Ltd.',
  'Karix Mobile Solutions',
  'Kaushalya Consultants Private Limited',
  'Kec International Limited',
  'Kipl',
  'Kirthik Roshan',
  'Knowledgeexcel',
  'Koinearth',
  'Konect.In',
  'Kpmg',
  'Krishaweb Pvt.Ltd',
  'Kv',
  'L & T',
  'L&S Digital Pvt Ltd',
  'La Renon Health Care Pvt Ltd',
  'Labcorp Drug Development',
  'Lake Chemicals',
  'Lawcubator',
  'Leadership Boulevard',
  'Leap Finance Or Leap Scholar',
  'Leverage Edu',
  'Lg Soft India',
  'Liinforce Technology Pvt Ltd',
  'Lokendra Sethi',
  'Lumenci',
  'Lumina Datamatics',
  'Luxoft',
  'Luxoft Poland',
  'Madheswaran',
  'Madhya Pradesh Gramin Bank',
  'Magadh Digital Solutions Private Limited',
  'Marelli Motherson Auto Suspension India Pvt Ltd',
  'Marlabs',
  'Maxonic',
  'Md Abusufyan Alam',
  'Mercury Air Technic',
  'Metacube Software Pvt Ltd',
  'Mgm Public School, Bilaspur',
  'Mobikode',
  'Mobile Programming India Pvt Ltd',
  'Mobile Programming Pvt Ltd',
  'Mobiquity Soft-Tech Pune',
  'Modern Agency',
  'Monster',
  'Morgan Stanley',
  'Movate Technologies',
  'Muthoot Finance',
  'Nablasol',
  'Nagnathappa Halge College Of Engineering',
  'Nandi Toyota',
  'Napier Healthcare',
  'National Informatics Centre',
  'Navisite',
  'Navjoy',
  'Nec Technologies India Limited',
  'Neosoft Technology',
  'Neoteq',
  'Nest Digital',
  'Netmeds',
  'Netscribes',
  'Netsmartz',
  'Network Security Engineer (Tac) At Sophos',
  'Neuranceai Technologies',
  'Newgen Software',
  'Newgen Software Technologies',
  'Niit Foundation',
  'Nira Life Sciences Pvt Ltd',
  'Niraltek Solutions',
  'Nitin Goel',
  'Nitin Gupta',
  'Nitin Shukla',
  'Nitor Infotech',
  'Niveus Solutions',
  'Nobroker.In',
  'Nouvel Tech Pvt Ltd.',
  'Novac',
  'Ntt Data Business Solutions Private Limited',
  'Nuance India Private Limited',
  'Nuance India Pvt Ltd',
  'Nucleus',
  'Nutanix',
  'Oati Mohali',
  'Ocrolus East Pvt Ltd',
  'Onit India',
  'Online Tution Centre',
  'Opgc',
  'Oracle Financial Services Software',
  'Outgrow.Co',
  'Paisalo Digital Limited',
  'Paycraft Solutions',
  'Paytm',
  'Pedesis Pvt Ltd.',
  'Persistent Systems Limited',
  'Pfs Web Solutions',
  'Pharmeasy',
  'Piton Systems Pvt Ltd',
  'Pivotrics Technologies Llp',
  'Plm Nordic',
  'Pnb Metlife',
  'Pnc Infratech Ltd',
  'Powerschool Group Llc',
  'Powerstores',
  'Prakash Kumar',
  'Pranav Nit',
  'Prashant Dasre',
  'Presistent Systems Limited',
  'Private',
  'Private Company',
  'Priya Softweb Solution',
  'Process Associate',
  'Process Excutive',
  'Profinch Solutions',
  'Pucho Digihealth',
  'Qa Manager',
  'Ql2 Software',
  'Qlab Technologies Pvt Ltd',
  'Qualitest',
  'Qualitrix',
  'Qualitykiosk Technologies Pvt Ltd',
  'Quant Systems',
  'Quantiphi Analytics Solution Private Limited',
  'Quara Digital',
  'Radha Group Toyota',
  'Radiant English Academy',
  'Radius Global Solutions',
  'Rahul Sonawane',
  'Ram Chandra Chandravansi University',
  'Ranosys',
  'Raymond Silver Spark Apparel Ltd',
  'Razorpay',
  'Refex Energy Ltd',
  'Reignite Corporate Services Ltd Pvt',
  'Reliance Jio Infocomm Limited',
  'Reliance Jio Infocomm Ltd',
  'Renault Nissan',
  'Resourcepro',
  'Retisio',
  'Reward360',
  'Risesmart',
  'Rocket Software',
  'Ronin Labs',
  'Rootbridge',
  'Royal Motors',
  'Rythmos India Pvt Ltd',
  'Sachin',
  'Sahil',
  'Salesforce',
  'Samsung Electronic India Pvt',
  'Sanria Engineering & Consulting Pvt Ltd',
  'Sapiens Technology',
  'Sark Genex',
  'Sbi',
  'Sbilife Insurance Co.Ltd',
  'School',
  'Sdet',
  'Seclance',
  'Self-Employed',
  'Semnox Solutions',
  'Senior Tester',
  'Seva Bharati',
  'Sganalytics',
  'Shaikshavali Tangadona',
  'Shilpa Y N',
  'Shree Vishwangad Dental Clinic',
  'Shri Vinayak Chemex',
  'Sidekick Technology',
  'Signature Consultants',
  'Simplilearn',
  'Sinai Enterprises',
  'Sisa Information Security Pvt. Ltd',
  'Site Reliability Engineer',
  'Sitel India Private Limited',
  'Sixsigma Softsolution Pvt Ltd',
  'Skill Lync',
  'Skk Infra',
  'Smart Connect Technology Private Limited',
  'Snapdeal',
  'Softthink Solutions',
  'Software Developer',
  'Software Engineer',
  'Software Testing Engineer',
  'Solugenix India Pvt Ltd',
  'Songdew',
  'Sourcework Business Solution Pvt Ltd',
  'Spacebasic',
  'Spearmint',
  'Spi Equipments India Pvt Ltd',
  'Springer Nature Technology And Publishing Solution',
  'Sri Wright Homes',
  'Srikanth',
  'Srinivas',
  'Stanventures Private Limited',
  'State Bank Of India',
  'State Street Syntel Pvt.Ltd',
  'Stellantis',
  'Sterling',
  'Stewart Title Insurance Company',
  'Straive',
  'Stu',
  'Suryapriya Constructions Pvt Ltd',
  'Svg Coaching Institute',
  'Synapse India',
  'Synergy System And Solutions. Pvt. Ltd.',
  'Syoft',
  'Systech Solutions',
  'Systems Engineer',
  'T-Systems',
  'Taskus',
  'Tata Project Ltd',
  'Tata Steel',
  'Tatvasoft',
  'Te Connectivity',
  'Teacher',
  'Teamlease Service Ltd',
  'Tech Influx Pvt Ltd',
  'Techahead',
  'Techchefz Pvt. Lmt',
  'Technical Executive',
  'Technoidentity',
  'Technomak Solutions',
  'Tecnoboot Pvt Ltd',
  'Tejnaksh Helthcare Pvt.Ltd',
  'Tele Sale',
  'Telus International',
  'Temenos',
  'Terra Logic',
  'Test Yantra Software Solutions Pvt Ltd',
  'Testbook',
  'Testingxperts',
  'Testyantra',
  'The Bhopal School Of Social Sciences',
  'The Homework App',
  'The New India Assurance Co. Ltd.',
  'Thinksys',
  'Tietoevry India Private Limited',
  'Townvu',
  'Treft Systems',
  'Trek Bikes',
  'Tricor',
  'Trinetium Tech Private Limited',
  'Trisys It Services Pvt Ltd',
  'Truking Technology India Pvt Ltd',
  'Tudip Technology',
  'Tvsnext',
  'Tyto Software',
  'Uber',
  'Uccpl',
  'Udhayas Foods',
  'Uias&R Pvt Ltd',
  'United Health Group',
  'Unotech Software Pvt. Ltd',
  'Ups Logistics',
  'Us Technologies',
  'Ust Global Inc, Bangalore',
  'Utkal Labs Pvt. Ltd',
  'Vaaan Infra Pvt Ltd',
  'Vaisesika Consulting Pvt,Ltd',
  'Valocity Global',
  'Value Momentum Software Solutions',
  'Varroc',
  'Varshney Infotech Pvt Ltd',
  'Vastraapp',
  'Vastraapp Charmeuse Technologies Pvt Ltd., Ahmedab',
  'Vaswani Group',
  'Vcare Skin Clinics',
  'Veave Technologies',
  'Vertafore India Pvt. Ltd',
  'Vfs Global',
  'Vibrent Health',
  'Vinclis Technologies Pvt Ltd',
  'Vipro',
  'Virtusa Consulting India',
  'Visa Inc',
  'Vishal Bakery',
  'Vishwaradhya',
  'Vlink Inc',
  'Vn2 Solutions Pvt Ltd',
  'Vodafone',
  'Volvo Construction Equipment',
  'Vowel School',
  'Vvdn Technologies Pvt Ltd',
  'Vymo',
  'Walmart Global Tech India',
  'Wattmonk Technology Pvt Ltd',
  'Webasto Roofsystem',
  'Webhelp',
  'Webkorps',
  'Webnappmakers, Llc',
  'Websense',
  'Website Development',
  'Whitevox',
  'Winst Infra Solutions Private Limited',
  'Winx Consulting Services',
  'Wisdmlabs',
  'Wizklub',
  'Wollschlaeger Technical Systems Llc',
  'Xeeva India Pvt. Ltd.',
  'Xoriant',
  'Xoriant Solution Pvt Ltd',
  'Xoriant Solutions',
  'Xyz',
  'Yardi Software India Pvt Ltd Pune',
  'Ye',
  'Zaloni',
  'Zapbuild',
  'Zeniosedge',
  'Zerozilla',
  'Znalytics Business Solutions',
  'Zodiac Solutions',
  'Ibm ',
  'Hcl',
  'Ericsson',
  'Zoho',
  'Sapient',
  'Mphasis',
  'Atos',
  'Aricent',
  'L&T Infotech',
  'Ust',
  'Honeywell',
  'Ep Technologies',
  'Harvest Future Consultants',
  'Oracle',
  'Alcatel',
  'Dell & Emc',
  'Visionary Rcm',
  'Sap',
  'Cerner Healthcare Solutions',
  'Cgi',
  'Ford',
  'Ntt Data',
  'West Line',
  'Intel',
  'Zensar',
  'Tata Elxsi',
  'St Micro Electronics',
  'Cadd Centre',
  'Itc Infotech',
  'Cisco',
  'Gemini Communication',
  'Vee Technologies',
  'Airtel',
  'Godrej&Boyce',
  'Flextronics',
  'Siemens Informations Systems',
  'Unisys',
  'Microsoft',
  'Huawei',
  'Csc',
  'E&Y',
  'Hexaware',
  'Scope International',
  'Aditya Group(Trading,Insurance)',
  'Css Corp',
  'Span Technology',
  'Excelacom Technologies',
  'Genworks Health .',
  'Hdfc',
  'Jp Morgan',
  'Wellsfargo',
  'Verizon',
  'Broadcom',
  'Wolter & Kluwer',
  'Hcl Bpo',
  'Ifb',
  'Tata Technologies',
  'Blackrock',
  'Philips',
  'Mahindra Comviva',
  'Synopsys',
  'Bnp Paribas Sundaram',
  'Sonata Software',
  'Jbm Auto Systems',
  'Jra Analytics',
  'Savysoft',
  'Sterlite',
  'Torry Harris',
  'Sasken',
  'Amadeus',
  'Hyundai',
  'Thought Works',
  'Igate',
  'Ge',
  'Bosch Global Services',
  'Teradata',
  'Cadeploy',
  'Decathlon',
  'Igt',
  'Tavant Technologies',
  'Paypal',
  'Sify',
  'General Motors',
  'Gofrugal',
  'Johnson',
  'Gm',
  'Reliance Jio Infocom',
  'Qualcomm',
  'Metricstream',
  'Netapp',
  'Aig(Analytics,Coe)',
  'Mastek',
  'Pramati Technologies',
  'Icici',
  'Pegasystems',
  'Arcadis',
  'Fico',
  'Pcs',
  'Akamai',
  'Intuit',
  'Nuance',
  'Grofers',
  'Genesys',
  'Astrazeneca',
  'Phoenix Maritime',
  'Rr Donnelley',
  'Netcracker',
  'Adecco',
  'Coromandel Electronics',
  'Expicient Software',
  'Godrej',
  'Just Connect Electricals',
  'Logic Information Systems',
  'Misys',
  'Sankalp',
  'Visa',
  'Tesco',
  'Novartis',
  'Arista',
  'Infineon Technologies',
  'Reverie',
  'Zopnow',
  'Lister',
  'Agni Tech',
  'Applied Materials',
  'Danfoss',
  "Dr.Reddy'S",
  'Escorts Agri Machinery',
  'Pmrt',
  'Solartis',
  'Soliton Technologies',
  'Verity Knowledge',
  'Vuram',
  'Volvo',
  'Sony',
  'Ims Health',
  'Unitech Transfer',
  'Sprinklr',
  'Isoft',
  'Tata Industries',
  'Shapoorji Pallonji & Co',
  'Sanmar',
  'Bpl Medical Technologies',
  'Century Link',
  'Dckap Technologies',
  'Future First',
  'Latent View',
  'Mobikasa',
  'Spcl',
  'Aditi',
  'Polaris',
  'Renault',
  'Caterpillar',
  'Schneider',
  'Cleartrip',
  'Just Dial',
  'Ednet',
  'Mistral',
  'Perifos Software',
  'Kotak Mahindra',
  'Thomas Innovations',
  'Air Wood',
  'Boston Technologies',
  'Customerxps',
  'Hubert',
  'Idbi',
  'Jaro Education',
  'Athena Health',
  'Netskope',
  'Thorogood',
  'Vedanta',
  'Zifo',
  'Zoomcar',
  'Toshiba',
  'Software Ag',
  'Manhattan Associates',
  'Cadence',
  'Nokia',
  'Via.Com',
  'Time',
  'Aci Worldwide Solutions .',
  'Subex',
  'Plintron',
  'St Ericsson',
  'Care It Solutions',
  'Continental Corporation',
  'Fidelity Software',
  'Maruti Suzuki',
  'Mrf',
  'Tce Consulting Engineers',
  'Apigee',
  'Infinera',
  'Atmel',
  'Sabre Technologies',
  'Aruba Networks',
  'Myntra',
  'Urban Ladder',
  'Moonraft',
  'Perot',
  'Keane',
  'Coca Cola',
  'Rolon Seals',
  'Vernalis',
  'Soctronics',
  'Adani',
  'Adwyze',
  'Bharat Fritz Werner',
  'Brigade Group',
  'Cloud Assert',
  'Credr',
  'Gigamon',
  'Global Logic',
  'Grant Thornton',
  'Greatwest',
  'Hexagon',
  'Instamojo',
  'Ittiam Systems',
  'Janalakshmi Finance Services',
  'Kreatio',
  'Maveric',
  'Mckinsey',
  'Okutech',
  'Tvs Motor Ppo',
  'Valeo',
  'Verticurl Solutions',
  'Zopper',
  'Syntel',
  'Commvault',
  'National Instruments',
  'Jda Softwares',
  'Inmobi',
  'Salt Side',
  'Volante Tech',
  'Ramco',
  'Comviva Technologies',
  'Advisory',
  'Allgo Systems',
  'Bajaj Capital',
  'Brakes',
  'Broadsoft',
  'Chain Systems',
  'Delphi',
  'Eds',
  'Hafele',
  'Hinduja Global Services',
  'Ideal Celler',
  'Ilink Systems',
  'Indegene Lifesystems',
  'Ingersoll',
  'Kaar Tech',
  'Lmw',
  'Numerify',
  'Nxp',
  'Odessa',
  'Puravankara Projects',
  'Qed 42',
  'Skf',
  'Spic',
  'Tessolve',
  'Ti',
  'Tismo Technologies',
  'Titan',
  'Tvs Logistic Services',
  'Uds',
  'I-Flex',
  'Sutherland Global',
  'Practo',
  'Bosch',
  'Flipkart',
  'Helpchat',
  'Zomato',
  'Think & Learn',
  'Vantage Ago',
  'Cei America',
  'Chainsys',
  'Asahi',
  'Austin Medical Solutions',
  'Besto Mech',
  'Binge Eservices',
  'Cartoon Mango',
  'Chrysler',
  'Edelweiss',
  'Endurance International Group',
  'Expertus',
  'Farmz2U',
  'Fl Smidth',
  'Hul',
  'Idmc',
  'Ire-Tex',
  'Loyal Housing',
  'Mindmade Technologies',
  'Neudesic',
  'Niit',
  'Nit Data',
  'Nse Infotech',
  'Open Silicon Research',
  'Playsmart (Help Chat)',
  'Polycom',
  'Pqe',
  'Rane',
  'Sjc Group',
  'Tec Dess',
  'Trans Healthcare',
  'Voltas',
  'W.S. Atkins Global',
  'Wabag',
  'Whirlpool',
  'Wissen',
  'Yokogawa',
  'Nvidia',
  'Schlumberger',
  'Wns Global',
  'Avaya',
  'Inautix',
  'Seagate',
  'Redmart',
  'Embed Ursystem',
  'Ivtl',
  'Cloud Lending',
  'Onmobile',
  'Royal Enfield',
  'Urjanet',
  'Afcons',
  'Airvana Networks',
  'Airwatch',
  'Cameron',
  'Capillary',
  'Hashedin Technologies',
  'Codingmart',
  'Gigsky',
  'Hero Moto Corp',
  'Hiree',
  'Honeywell Bpo',
  'Hycom Engineering',
  'Icon Resources',
  'Idea Elan',
  'Ins Zoom Technolgy Services',
  'Intellectyx',
  'Inteva',
  'Kanteron (Uk)',
  'Lybrate',
  'Medd',
  'Mentor Graphics',
  'Zs Associates',
  'N2K Technologies',
  'Nsi Infinium Global (Infibeam.Com)',
  'Orient Cement',
  'Payu',
  'Pokarna',
  'Quadrant Technologies',
  'Railsdata',
  'Redington',
  'Sigmaways',
  'System Insights',
  'Teksystems',
  'Textron',
  'Tycoint',
  'Vdart',
  'Webdunia',
  'Hp-R&D',
  'Zettata',
  'Samsung',
  'American Express',
  'Ca',
  'Goldman Sachs',
  'Code Nation',
  'Nec Corporation',
  'Aspire System',
  'Directi',
  'Ninjacart',
  'Swiggy',
  'Pervacio',
  'Hilti',
  'Elitmus',
  'Ambigai Contancy',
  'Aryaka Networks',
  'Maq Software',
  'Borgwarner Morse Tec Murugappa',
  'Britannia',
  'C42',
  'Careernet',
  'Ces It',
  'Chalkstreet',
  'Coffeeday',
  'Colgate Palmolive',
  'Eastern Asphalt And Mixed Concrete Company',
  'Godot Entertainment And Media',
  'Headhonchos',
  'Inszoom',
  'Kgisl',
  'Kirusa',
  'Lennoz',
  'Lucideus - Cyber Space Security',
  'Mobicip',
  'Object Frontier Services',
  'Prdc',
  'Quascenta Sensing',
  'San Engineering',
  'Schoolcom',
  'Schwing Stetter',
  'Skava Technologies',
  'Sl Lumax',
  'Sofgen',
  'Stellium Consulting',
  'Syrma',
  'Thedatatech',
  'Vertx Solutions',
  'Vocera',
  'Vortex Engineering',
  'Wabco',
  'Wanto',
  'Ge Health Care',
  'Nokia',
  'Adobe',
  'Payoda Technologies',
  'Walmart Labs',
  'Danske It',
  'Apptio',
  'Shell',
  'Juniper',
  'Symantec',
  'Convergys',
  'Micro Focus',
  'Credit Suisse',
  'Hpe',
  'Mcafee',
  'Epsilon',
  'Redhat',
  'Informatica',
  'Mahindra',
  'Expedia',
  'Barclays',
  'Arcesium',
  'Linkedin',
  'Exl',
  'Fss',
  'Mercedez Benz',
  'Apple',
  'Sandisk',
  'Kuliza',
  'Dbs Bank',
  'Tata Communications',
  'Coviam',
  'Kpit Cummins Infosystems',
  'Xerox',
  'Xilinx',
  'Trimble',
  'Deutsche Bank',
  'Rakuten',
  'Rbs',
  'Riverbed',
  'Ea',
  'Rsa Security',
  'Betsol',
  'Groupon',
  'Facebook',
  'Sundhram Clayton',
  'Comcast',
  'Novell',
  'Yahoo',
  'Motorola',
  'Autodesk',
  'Saint Gobain',
  'Logitech',
  'Rackspace',
  'Appdynamics',
  'Nest',
  'Franklin Templeton',
  'Tesla',
  'Brocade',
  'Zynga',
  'Force 10 Networks',
  'Otis',
  'Ericsson R&D',
  'Hindustan Unilever',
  'Bridgei2I Analytics Solutions',
  'Pmc-Sierra',
  'Codebrahma',
  'Nortel',
  'Yodlee',
  'Tripadvisor',
  'Twitter',
  'Mettl',
  'Blue Star Infotech',
  'Ing',
  'Target Corporation',
  'Tumblr',
  'Square',
  'Yext',
  'Zillow',
  'Unisys Global Services',
  'Ebay',
  'Airbnb',
  'De Shaw',
  'Dropbox',
  'Foursquare',
  'Flutura',
  'Fractal Analytics',
  'Infibeam',
  'Ip Infusion',
  'Nakuri.Com',
  'Naukri',
  'Ola',
  'Quikr',
  'Rediff.Com',
  'Shopclues',
  'Tally',
  'Via.Com',
  'Voonik',
  'Zivame',
  'Makemytrip',
  'Cube',
  '135 Tech Labs',
  '16 Works',
  '20-20 Technologies',
  '24*7 I Labs',
  '3Dplm Software Solution',
  '42 Hertz',
  '6Sense',
  '6Thenergy',
  '8X8',
  '99Acres.Com',
  'A9',
  'Aakash Institute',
  'Aarvee Associates',
  'Abn',
  'Above Solutions',
  'Absolut Data Research & Analytics',
  'Absolute Surveyors',
  'Absyz',
  'Abyeti Technologies',
  'Accel',
  'Accel Frontline',
  'Access Point It Services',
  'Accolite',
  'Accord',
  'Accurate Steel',
  'Aco Technologies',
  'Addison & Co',
  'Adroll',
  'Advanced',
  'Advent',
  'Adventures Commodity Services',
  'Advinus Therapeutics',
  'Aec',
  'Agami Systems',
  'Agc',
  'Agile Point Software',
  'Agilent Technologies',
  'Ags Healthcare',
  'Airbus',
  'Aircel',
  'Airlines',
  'Airmaasters',
  'Akr Associates',
  'Aks Software',
  'Akzo Nobel Coatings',
  'Al Ghurair Group',
  'Alance Technologies, Chennai',
  'Alforn Systems',
  'Aliens Group',
  'Alliance Data',
  'Alligator Software',
  'Allsec Technologies',
  'Allstate',
  'Alpha 9 Marine',
  'Alshaya',
  'Alstom',
  'Altair',
  'Althea Systems & Software .',
  'Altiux',
  'Amada Soft . - Trainee Engineer',
  'Amaella',
  'Amagi',
  'Amalgamations Valeo Clutch .,',
  'Ameex Technologies',
  'American Megatrends',
  'Amphisoft',
  'Ample Technologies (Apple)',
  'Amro',
  'Amul',
  'Amyris Biotechnologies',
  'Anabond',
  'Analog Devices - Design',
  'Analysts International',
  'Analytical Quotient',
  'Angel Broking',
  'Anheuser Busch Inbev - Associate Data',
  'Ani Technologies . - Software',
  'Annam Soft',
  'Antuit',
  'Anugraha Software',
  'Aon Hewitt',
  'Apna Technologies',
  'Apollo',
  'Appco',
  'Applied Controls & Automation',
  'Appnomic Systems',
  'Appolo Tyres',
  'Apsara Innovations',
  'Aqua Star Ro Systems',
  'Aquasub Engineering',
  'Araldite',
  'Arco',
  'Arcot R&D Software',
  'Areva',
  'Argo Ai',
  'Aristocrat It Solutions',
  'Arjun Agri & Bio Energy',
  'Arkin',
  'Aroop Technologies',
  'Arryve',
  'Arthamoney(Times Of Group)',
  'Artoo It Solutions .',
  'Arvind Remedies',
  'Asana',
  'Ascendant',
  'Ascent Circuits',
  'Ascil',
  'Ashok Leyland',
  'Asia Motor',
  'Ask Media Group',
  'Aspiring Minds',
  'At Kearney',
  'Athdl Design Automation',
  'Atkins',
  'Atlas',
  'Atlogys',
  'Atomic',
  'Atotech',
  'Audi',
  'Auma',
  'Aurigo Software Technologies',
  'Aurolab',
  'Austria Microsystems',
  'Autocomp',
  'Autoform',
  'Autoliv',
  'Auxillaries',
  'Avalara',
  'Avalon Technologies',
  'Avanti Learning Center',
  'Avanttec Medical Systems (P)',
  'Avtec',
  'Axcient',
  'Axes Technologies',
  'Axiom',
  'Axis Global Automation',
  'Aztec Auto',
  'Azul Systems ()',
  'B & C Tech Service',
  'B.H.E.L',
  'Baci Continuum-Bank Of America - Senior',
  'Bahwan Cybertech',
  'Bain Consulting',
  'Balsam Brands',
  'Bank Bazaar',
  'Basf',
  'Battery Engineers',
  'Bcg',
  'Beckon',
  'Beehyv Software Solutions',
  'Belair Corporation',
  'Benz',
  'Berger Paints',
  'Beroe',
  'Bgr Energy',
  'Bhabha',
  'Bhagwati Designs',
  'Bharat Electronics',
  'Bhartiya Group',
  'Bhrigus Software',
  'Bidgely Technologies .',
  'Bill Forge',
  'Biochemicals',
  'Bioclinica',
  'Biocon',
  'Biogenex',
  'Biopharma',
  'Birla Life Insurance Company',
  'Bizense Technologies - Adatrix - Product',
  'Biztalk 360',
  'Black Magic Toners .,',
  'Blackberry',
  'Blackstone',
  'Bloom Consulting',
  'Bloom Energy',
  'Blue Jeans Networks',
  'Blue Nile',
  'Blue Ocean',
  'Blue Star Air-Conditioners',
  'Bmw',
  'Bnt Connections',
  'Bodh Tree Consulting',
  'Bonaven Software',
  'Bot Code',
  'Box',
  'Bpl',
  'Brakelings',
  'Bravolucy',
  'Bridge Hr Consulting',
  'Brightcove',
  'Britmex',
  'Broadridge Financial Solutions',
  'Brokerage Free',
  'Brooks Sports',
  'Bscpl Infrastructure',
  'Buhler',
  'Build Infotech',
  'Bulls',
  'Burning Glass Technologies',
  'Busigence',
  'Butterfly Innovations(Collpoll)',
  'Buzz Works',
  'C-Cubed Solutions',
  'C2R Ventures',
  'Caarpus Technology Services',
  'Cads Software',
  'Cafe Coffee Day',
  'Caliber Financial Services',
  'Caliber Interconnect',
  'Caliber Point',
  'Caparo',
  'Capco Technologies',
  'Capital Iq',
  'Caradigm',
  'Caratelane',
  'Cargill',
  'Caritor',
  'Carlease.Com',
  'Catamaran Venturs',
  'Catex Petro Chemicals',
  'Cavinkare',
  'Cavium Networks',
  'Cbs Techno Co - Mechanical',
  'Cccl',
  'Cci – Control Component .',
  'Ccil',
  'Celia Infotech Software Solutions',
  'Cements Capital',
  'Ceramics',
  'Cethar',
  'Cg Vak',
  'Changepond Technologies',
  'Chargebee Technologies',
  'Charted',
  'Checktronix',
  'Chella Software [P]',
  'Chemplast',
  'Chemtex',
  'Chennai Radha Engineering Works (Crew)',
  'Chettinadu Hospital',
  'Chipquest',
  'Chromatographs',
  'Chronous Corporation',
  'Ciena',
  'Cipla',
  'Circor',
  'Cither Vessels',
  'Citicorp',
  'Citos',
  'Citrisys Solutions',
  'Clayton',
  'Cleanmax Solar - Business Development',
  'Cleartax',
  'Cloud Enablers',
  'Cloud Infotech',
  'Cloudthat Technologies',
  'Coats',
  'Coda Global',
  'Collabera',
  'Common Floor',
  'Comstar Automotive Technologies',
  'Concentrix Corporation',
  'Concur',
  'Conexant',
  'Connections Of New York',
  'Connectors Marketplace',
  'Consleague',
  'Consortium For Dewats Dissemination (Cdd) Society',
  'Consul Consolidated',
  'Controls',
  'Cordys',
  'Cosign (P)',
  'Cosmic Circuits',
  'Cotton Blossom',
  'Cotton Buds',
  'Covansys',
  'Covenant Consultants',
  'Creation Technology Solutions',
  'Credit Point',
  'Crewkarma',
  'Criteo',
  'Crittercism',
  'Crompton Greaves',
  'Cruise Line Ship Management',
  'Cryo Bank',
  'Cryosave',
  'Crystal Hues',
  'Cue Learn (Cuemath)',
  'Cusdelight',
  'Cvc',
  'Cyient',
  'Cypress Semiconductors',
  'Daasity',
  'Daikin Airconditioning',
  'Daimler',
  'Dallas Texas Consultants',
  'Dalmia',
  'Dar-Al',
  'Dart',
  'Data Track',
  'Datalaab',
  'Dataxu',
  'Dcw',
  'Dec Infra',
  'Deccan Coach Builders',
  'Dee Be Con',
  'Deevita Technologies',
  'Defiance',
  'Deki',
  'Dell Perot Systems',
  'Deltax',
  'Dep',
  'Derrick Petroleum',
  'Design Tribe',
  'Desmet Ballestra',
  'Deutch Bank',
  'Dharani',
  'Dhl',
  'Dhyan Infotech',
  'Diamond Storm Technology',
  'Dice',
  'Digital Juice Animations',
  'Digital Nirvana',
  'Dim Dim',
  'Directo',
  'Dish',
  'Disha Interiors',
  'Dishcraft Robotics',
  'Ditto',
  'Dmrc',
  'Dms Financial Sevices Company',
  'Docusign',
  'Doha',
  'Dohler',
  'Dolphin',
  'Donfoss Industries',
  'Doodleblue',
  'Doshin Infotech',
  'Dow',
  'Dr. Mehta’S Hospital',
  'Dr.Mohan’S Diabetes Specialities Centre',
  'Drive.Ai',
  'Dsl',
  'Dsq Software',
  'Dumbelled',
  'Durian',
  'Dymos Lear Automotive',
  'E Sewa Phone Pay',
  'E Stomi Technologies',
  'E Wave Networks',
  'E-Bowl Technologies',
  'E-Care - Chennai',
  'E-Con System',
  'Easi Engineering',
  'East West Pharma',
  'Eastside Ubator',
  'Easy Solutions',
  'Eaton',
  'Eb Step',
  'Ecil',
  'Ecolab',
  'Econ System',
  'Edac(Spic)',
  'Edgeverve',
  'Edss',
  'Efficient Frontier',
  'Efunds',
  'Efx Magic (Prasad Studios)',
  'Einnel',
  'Element-K',
  'Elgi',
  'Elton Technologies',
  'Elxsi',
  'Embdes Tech',
  'Embedded Infotech',
  'Embedur',
  'Emrill Services Llc',
  'Emw',
  'Enercon',
  'Energyly',
  'Enfield',
  'Enfrien Innovations Llp - Design Engineer',
  'Enterprisedb Software',
  'Entrayn',
  'Entrinsico',
  'Enzen Global Solutions',
  'Epic Solutions',
  'Episource',
  'Equus Ict Services',
  'Esab',
  'Esi Software',
  'Esm',
  'Essar',
  'Essential Products',
  'Eta – Ascon',
  'Etouches',
  'Eureka Forbes',
  'Eurothem Del',
  'Eventbrite',
  'Evivehealth',
  'Evolvics',
  'Exa Corporation',
  'Examly',
  'Exeter Group',
  'Extrusions',
  'Factset Systems',
  'Fair Isaac',
  'Farm',
  'Fasteners',
  'Fedby Information Services',
  'Federal Bank',
  'Feedback Ventures',
  'Fenner',
  'Festo',
  'Fiat',
  'Fiberlink Software',
  'Ficci',
  'Fiitjee',
  'Finmechanics - Associate Consultant',
  'Firetide Networks',
  'First America',
  'First Apex Software',
  'First Flight Couriers',
  'First N Corporation',
  'Fitness One Group',
  'Flakt',
  'Flavourade',
  'Flextrade',
  'Flowserve',
  'Flsmidth Minerals',
  'Fluke',
  'Fmc Technologies',
  'Focus Management',
  'Formative',
  'Fortis',
  'Four Kites',
  'Four Systems',
  'Foxconn Fih ()',
  'Fred Hutchinson',
  'Freedom Ophthalmic',
  'Freescale Semiconductors',
  'Freshdesk',
  'Friens Engineering',
  'Frontier Business Systems',
  'Frontier Knitters',
  'Frost & Sullivan',
  'Fruzyme',
  'Fuji Tech',
  'Full Creative',
  'Fullerton Co',
  'Fun Dreamz',
  'Fuse',
  'Futura',
  'Future Capital Holding',
  'Futurenet Technologies',
  'Futures First',
  'Fuze Boz',
  'Fxlabs Studios',
  'Gabriel',
  'Galore Networks',
  'Gates Foundation',
  'Gati Kintetsu Express',
  'Gavs Technologies',
  'Gears',
  'Geekay Industries',
  'Gemba Concepts',
  'Geodesic Techniques',
  'Geometric Software',
  'Gestamp Automotive-Chennai',
  'Getit',
  'Gigamon',
  'Gimmal',
  'Glass',
  'Glaxosmithkline',
  'Glenwood Micro Systems',
  'Global Analytics',
  'Global Analytics',
  'Global Edge',
  'Global English',
  'Global Scholar',
  'Global Technologies',
  'Globarena',
  'Globollogic',
  'Gmmco',
  'Gmr Infrastructure',
  'Gnostice Information Technologies',
  'Go Green Bov',
  'Godb Tech',
  'Golden Hyundai',
  'Goms Electricals',
  'Good Rich / Utc Aerospace Systems',
  'Grail',
  'Gram Vidiyal Micro Finance',
  'Grammer It Solutions',
  'Grarri',
  'Great West Global',
  'Greaves',
  'Greaves Cotton .,',
  'Gridstone Research',
  'Gro',
  'Grow Technologies',
  'Grundfos',
  'Gsk',
  'Gtl',
  'Guruji.Com Software',
  'Haikudeck',
  'Hain Celestial Group',
  'Hakuna Matata',
  'Hanil Lear',
  'Harita Fehrer',
  'Harley-Davidson Motor Company',
  'Harman Engineers',
  'Hasura',
  'Hatsun',
  'Havells',
  'Hawe Hydraulik',
  'Headstrong',
  'Health Plan Systems',
  'Healthtap',
  'Heerema Marine Contractors',
  'Hexa Bytes Systems',
  'Hgst',
  'Himalaya Drug Company Private',
  'Hinduja Foundaries',
  'Hitech Robotics',
  'Hitwicket',
  'Hiveminds',
  'Holzwerk Interior',
  'Homeo',
  'Honda Motors Company',
  'Housing Home',
  'Houzz',
  'Hpcl',
  'Htc',
  'Hwashin Automotive',
  'I Discoveri',
  'I-Core Technologies',
  'I4U Labs',
  'Ibex',
  'Ibs',
  'Icomm',
  'Icreate Software',
  'Ict Equus P',
  'Id Matrix',
  'Ideation R & D Labs',
  'Idle Works',
  'Ifb Industries',
  'Iflow Soft',
  'Ihd Inudstries',
  'Ijm ()',
  'Ik Solutions',
  'Ikanos Communications',
  'Ikaria',
  'Ikoa Semiconductor',
  'Illumina',
  'Imagination Technologies',
  'Imaging',
  'Imc',
  'Imi Soft',
  'Imo',
  'Impact Engineering Solutions',
  'Inatech',
  'Inatech',
  'Inatech',
  'Indeed.Com',
  'Indu Plant Services',
  'Indus Ind Bank',
  'Indus Objects Tech Soft',
  'Indus Teqsite -Chennai',
  'Infotech Enterprises',
  'Infoview',
  'Infres Methodex',
  'Ingram Micro',
  'Inmage',
  'Innoppl Technologies',
  'Innovation Digital Transformation Services',
  'Inrix',
  'Inspirage',
  'Inspyrus',
  'Instartlogic',
  'Intacct Corporation',
  'Intact Gateway Solutions',
  'Intaspharma',
  'Integra Microsystem',
  'Intellect Design',
  'Intellectual Ventures',
  'Intelligroup Asia',
  'Inter Globe Technologies ',
  'Intergraph',
  'Interwoven Software',
  'Intimate Fashions',
  'Intwel Technologies',
  'Invensysinvictus Technology Solutions .,',
  'Inzoom',
  'Ion Exchange - Bangalore',
  'Iotabird',
  'Ip Rings',
  'Irobot',
  'Iryaa Consulting',
  'Isro',
  'Issm',
  'Itron',
  'Itrust',
  'Ivrcl',
  'Ivycomptech',
  'Jacobs',
  'Jade Global',
  'Jain Housing',
  'Japan Lighting',
  'Jasmin',
  'Jasubhai Engineering',
  'Jay Jay Mills',
  'Jean Martin Systems',
  'Jeppiaar Technologies',
  'Jesmo Infotech',
  'Jet',
  'Jiit',
  'Jindal Steels',
  'Jmc Projects',
  'Job Excell',
  'John Deere',
  'Jones Lang Lasalle',
  'Jsk Group',
  'Jsw Steel',
  'Jubilant Generics',
  'Jupiter Electronics',
  'Juspay Technologies',
  'Jvs Structural Engineering',
  'Jwt',
  'K-7 Computing Systems',
  'K-Soft',
  'Kacst, Saudi',
  'Kalisrp',
  'Kals Infosystems',
  'Kamalam Builders',
  'Kanbay',
  'Kapico Motors',
  'Karvy',
  'Kasura Technologies',
  'Keepworks',
  'Kef Infrastructure',
  'Kellton Financial Services',
  'Kenexa Technologies',
  'Kennametal',
  'Kerbs',
  'Keysight',
  'Kgfsl',
  'Khadim',
  'Khatib & Alami',
  'Kiit',
  'Kimls',
  'Kirazz',
  'Kirloskar',
  'Kiwi Foods',
  'Kk Thangamuthu Contractors',
  'Kla Tencor',
  'Kmc',
  'Knila It Solutions',
  'Knolskape Solutions',
  'Knowlarity Communications',
  'Knowledgefaber',
  'Kochar Infotech',
  'Kone Elevator',
  'Korrio',
  'Krawler Information Systems',
  'Kritikal Solutions',
  'Ksb',
  'Kumaran Systems',
  'Kupps & Sachs',
  'Kuruganti Computers',
  'Kvb',
  'Kvp Business Solutions',
  'L-Cube Innovative Solutions',
  'Lakshmi Vilas Bank',
  'Lam Research',
  'Lason',
  'Latentview Analytics',
  'Leadage',
  'Leaf Technologies',
  'Leapmotion',
  'Leitner',
  'Levi Strauss',
  'Leyland',
  'Lg Soft',
  'Limelight',
  'Lionbridge Tech',
  'Liquidhub',
  'Lms',
  'Lnmiit',
  'Logos Constructions',
  'Lsi Research & Development',
  'Lucas',
  'Lucid',
  'Luminosoft Technology',
  'M & B Technologies',
  'M Cube Technologies',
  'M-Phasis',
  'Mabani',
  'Macto Tools',
  'Madras Cements',
  'Mafiree',
  'Mafoi Management Consultants',
  'Magic Leap',
  'Magma Design Automation',
  'Magnasoft Tech',
  'Magneti Marelli Skh Exhaust Systems',
  'Magtorq',
  'Mahaveer Safety Glass',
  'Malladi',
  'Mallow Tech',
  'Mando Brakes',
  'Mangalore Chemicals & Fertilizers',
  'Many Tongues Media',
  'Mapal',
  'Maples',
  'Marchex',
  'Marg',
  'Marico',
  'Marketo',
  'Marvell Semiconductors',
  'Mascon',
  'Mashreq Bank',
  'Mathworks ',
  'Max Newyork Life Insurance',
  'Maxim Integrated',
  'Maxlinear Technologies',
  'Mayfield Robotics',
  'Mbit Wireless',
  'Mbt',
  'Mcfadyen Solutions',
  'Medaz',
  'Mediatek',
  'Megatrends',
  'Merit Software',
  'Metalpowder',
  'Metlife',
  'Mgk',
  'Michelin',
  'Mico',
  'Micro',
  'Microchip Technology',
  'Microsemi',
  'Microsystems',
  'Microtec Polymers',
  'Midas Communication Technolgies',
  'Miebach Consulting',
  'Milagrow Humantech-Pune',
  'Minmax Optimization',
  'Mithran Associates',
  'Mmc Infotech Services P',
  'Mmm Hospital',
  'Modular Auto',
  'Molecular Connections',
  'Montalvo Systems',
  "Moody'S Analytics",
  'Moog Controls',
  'Moogsoft',
  'Motek',
  'Mp Integrated Roofings',
  'Mpf Advisory',
  'Mss Engineering & Industrial Equipment',
  'Mubble',
  'Mulesoft',
  'Multivision',
  'Multucoreware',
  'Murugappa',
  'Must Garments',
  'Muthoot Corp',
  'Myally',
  'Myantra Software',
  'Mytrah',
  'Nalco',
  'Nation Star',
  'National Payment(Npci)',
  'National Plastics',
  'Nationstar Mortgage',
  'Nauvata Engineering',
  'Navia Markets',
  'Nbcuniversal',
  'Neev Information Technologies',
  'Neeyamo',
  'Neilsoft',
  'Neogov',
  'Neogrowth',
  'Neosme ( Kirazz)',
  'Nerdwallet',
  'Nestle',
  'Netafim',
  'Netcon Technologies',
  'Netel',
  'Network 18 ( Infomedia 18 .,)',
  'Newwave Computing',
  'Next Education',
  'Neycer',
  'Nfnlabs',
  'Nhance Engineering',
  'Nicholas Phiramal',
  'Nile Stream Info Technologies',
  'Nimble',
  'Nirals',
  'Nissan',
  'Nist',
  'Nitesh Estates',
  'Nittany',
  'Nogalis',
  'Novaweigh',
  'Novi Digital',
  'Nowpos Online Services',
  'Npcil',
  'Npl',
  'Nsh',
  'Nss Communications',
  'Ntt Communications',
  'Nucleus Software Technologies',
  'Numaligarh',
  'Numerics',
  'Nuvent Technologies',
  'O9 Solutions Management',
  'Obopay Mobile Technology',
  'Odyssey Technologies',
  'Office Tigers',
  'Ohm Energy Management System',
  'Okta',
  'Omics Publishing',
  'One Convergence',
  'Ongc',
  'Ontact',
  'Ooyala',
  'Opentable',
  'Opentext',
  'Optima Heat Technologies',
  'Optimizely',
  'Optimus Global Services',
  'Optym - Operations Research',
  'Orbitz Services',
  'Orchid Chemicals',
  'Origin Learning Solutions',
  'Osi Consulting (Osius)',
  'Osmosys',
  'Osp Group',
  'Osram',
  'P&G',
  'P3 Consulting',
  'Pace Micro Technology',
  'Packard',
  'Pandian Food',
  'Peek',
  'People Equity',
  'Perfect Gears',
  'Perkinelmer',
  'Peter England',
  'Petron Civil Engineering',
  'Photob Infotech',
  'Photon Infotech',
  'Pimco',
  'Piolax',
  'Pistons',
  'Plantronics',
  'Plast',
  'Play Factory',
  'Playdom',
  'Pocket Gems',
  'Point Tech',
  'Pointel Solutions',
  'Polytrusion .',
  'Pon Pure Chem',
  'Popular Automobiles',
  'Porch',
  'Porter',
  'Power2Sme',
  'Poynt',
  'Ppn Power',
  'Praxair',
  'Prematix Software',
  'Premier Biosoft',
  'Prestige',
  'Prime Soft',
  'Procyon',
  'Prodapt',
  'Prodex Technologies',
  'Progress Software',
  'Projects Eba',
  'Prokarma',
  'Prolab Soft',
  'Prompt Technologies',
  'Proptiger Realty',
  'Protech',
  'Prothious Engineering Services',
  'Puget Sound Energy',
  'Pumbs',
  'Qolsys',
  'Quadsel',
  'Qualcore Logic',
  'Quality Steel Solutions',
  'Quantitative Brokers - Algorithmic Trading',
  'Quest Global',
  'Quetzal Online',
  'Quick Logic',
  'Quintegra',
  'Quscient Technologies',
  'Quspec Consulting',
  'Qwest Telecom Software',
  'Radial Omnichannel Technologies',
  'Radien',
  'Radius',
  'Rameshwar Engineering',
  'Ramsays',
  'Ranbaxy',
  'Raremile Technologies',
  'Rational Interaction',
  'Rcm Infotech',
  'Real Image Media Technologies',
  'Realnetworks',
  'Reckitt Benckiser',
  'Redpine Signals',
  'Redword Shores',
  'Refinery',
  'Reliance ',
  'Reliance Bpo.,',
  'Reliance Industries',
  'Religare Commodities',
  'Resolve Technologies',
  'Responsys Business Solution',
  'Resq Technologies',
  'Rheinbrucke It Consulting',
  'Rialto Engineering',
  'Rivigo',
  'Rkm Power',
  'Roche Diagnostics',
  'Roku',
  'Rovi',
  'Royal Education Society',
  'S K Engineering Company',
  'Sae',
  'Safran',
  'Sage',
  'Saggezza',
  'Sai Technologies',
  'Saipem',
  'Sakin',
  'Salcomp',
  'Samcys Technologies',
  'Sanmina',
  'Sanspareil Technologies',
  'Saraplast',
  'Sarnoff',
  'Sartorious',
  'Satven(Sathyam Ventures)',
  'Saventech',
  'Scm Microsystems',
  'Scolis Technologies',
  'Se Electricals',
  'Secure Meters',
  'Seec',
  'Sensible Software',
  'Servion Global Services',
  'Seshasayee Paper Board',
  'Set Labs',
  'Shahsun',
  'Shanthi Gears',
  'Sharda Motors',
  'Shasun Pharmaceuticals',
  'Shippr Technologies',
  'Shoppers Stop',
  'Shoretel',
  'Siksha’O’Anusandhan University',
  'Silicon Partner Devices',
  'Silvan Labs',
  'Silverline',
  'Sim Technologies',
  'Simbus Technologies',
  'Simplex Infrastructures',
  'Simplify360',
  'Sipcot',
  'Sisa Inforamtion Security',
  'Siva Group',
  'Skanray',
  'Skh Metals',
  'Slash Support',
  'Slingmedia',
  'Sm Tech',
  'Smart Dv',
  'Smart Prix',
  'Smile Interactive Technologies',
  'Snap',
  'Sobha',
  'Social Frenz',
  'Societe Generale',
  'Sofs Technologies',
  'Soft Logic Systems',
  'Soft Solution',
  'Soft Tek',
  'Softjin Technologies',
  'Solverminds',
  'Sona Group Of Companies',
  'Sona Somic Lemforder Components',
  'Sonus Networks',
  'Sos Tech',
  'Sourcebits',
  'South Corporation',
  'Southern Agro Engine',
  'Spanco Telesystems',
  'Spikesource Software',
  'Spime',
  'Splunk',
  'Spo',
  'Sra',
  'Srinsoft Technologies',
  'Srm Tech',
  'Ss Information System',
  'Ssa Global',
  'Ssi',
  'Standard Chartered',
  'Standard Fireworks',
  'Statlabs',
  'Stella & Dot',
  'Stellar Information Systems',
  'Steria',
  'Sterling Resorts',
  'Stone Plus',
  'Straight Mobile Media Service',
  'Strand Life Sciences',
  'Strata Retail & Technology Services',
  'Strides',
  'Subhas Project & Marketing',
  'Success Factor',
  'Sum Total Systems',
  'Ridecell',
  'Sun Pharma',
  'Sundaram',
  'Suntec Systems',
  'Super Gas',
  'Supermico',
  'Sure Soft',
  'Surface Preparation Solutions & Technologies .',
  'Surprise Solutions',
  'Surya Builders',
  'Suzlon',
  'Swarna Pragati Housing',
  'Swelect Energy Systems',
  'Sybase Software',
  'Sycanet Solutions',
  'Symbion Solutions',
  'Synactive',
  'Syndicate',
  'Synergy Property Development Services',
  'Synfora',
  'Sysazzle',
  'Sysbiz Technologies',
  'Sysmex Corporation',
  'T D Power Systems',
  'Tachyon Technologies',
  'Tafe',
  'Taiwan Semiconductor Manufacturing',
  'Taj',
  'Tameer Consultants',
  'Tap Engineering',
  'Tarapore',
  'Tarnea',
  'Tata Tele Services',
  'Taulia',
  'Tayana Software',
  'Tcl',
  'Te Software Sevices',
  'Technip',
  'Techno Outsourcing',
  'Technoscion Solutions',
  'Technosoft Global Services',
  'Tejas Networks',
  'Tektronix',
  'Televentures',
  'Tencor',
  'Terex',
  'Texmo Industries',
  'Thati Consultants',
  'The Hindu Group - Ai Engineer',
  'The New N Express',
  'The Shelby Group',
  'The Steve Trautman Co',
  'The Today Group',
  'Theery',
  'Theme Technologies',
  'Theovel',
  'Thermax',
  'Think N Solutions',
  'Thinklabs Technosolutions',
  'Thinksoft Global Services',
  'Thirdware Tech',
  'Thomson Reuters',
  'Thumbtack',
  'Ti',
  'Tiara',
  'Tiger Analytics',
  'Tiino Techmations',
  'Tikona Digital Networks',
  'Timestone',
  'Timken',
  'Tmb',
  'Tmi Network',
  'Tmobile',
  'Tomrain Technologies',
  'Toofaan Software',
  'Toshibro',
  'Total Environment Building Systems',
  'Total Presentation Devices',
  'Toubro',
  'Toyo Engineering',
  'Toyota',
  'Tpf Software',
  'Tpsc',
  'Trc Engineering',
  'Tredence',
  'Triad',
  'Trigent',
  'Triniti',
  'Triplt',
  'Triveni Engineering',
  'True Waves',
  'Truly Intelligent',
  'Trw',
  'Ttk Prestige',
  'Turbo Energy',
  'Ture Technologies',
  'Turn',
  'Tusker Travels',
  'Tvm Signaling',
  'Tyco',
  'Ucal Fuel Systems',
  'Udyog',
  'Ultramarine & Pigments',
  'Uniliver',
  'Union',
  'Unique Creations & Co',
  'United Breweries',
  'United Engineering',
  'United Software',
  'Upstart',
  'Urc Construction',
  'Urmi Systems',
  'Urmila Enterprises',
  'Usha Armour',
  'Usi Internetworking Services',
  'Utc Aerospace Systems',
  'Uurmi',
  'V-Edge Software Labs',
  'Va Tech',
  'Vallies Software Labs',
  'Value Source',
  'Valued Epistemics',
  'Vantage Research - Model Development',
  'Veda E Mudra',
  'Vedams Software',
  'Vedantu',
  'Veesons',
  'Veesons Energy Systems .,',
  'Vel Micro Solutions',
  'Venture Lighting -Chennai',
  'Vepl',
  'Vertafore',
  'Vesta Equipment',
  'Veveo',
  'Vhel',
  'Videocon',
  'Viella',
  'Vijaya Bank',
  'Vin Biotech Systems',
  'Vinayak Infotech',
  'Vip Industries',
  'Viralheat',
  'Vistaar Financial Solutions',
  'Vitag Technologies',
  'Vitesse',
  'Vizual Business Tools',
  'Vlocity',
  'Vt Manpower Consultancy Services',
  'Vwr',
  'Walmart Global Technology Services',
  'Wandisco',
  'Waveaxis',
  'Way2Wealth',
  'Webaroo',
  'Webcreats',
  'Weg Inds',
  'Weg Industries-Hosur',
  'Weir Ensci',
  'Weir Mineral',
  'Welset',
  'West End Capital Management',
  'Whiz Technologies',
  'Wichitra Auto',
  'Widas',
  'Wildcraft',
  'William Hare',
  'Winwind',
  'Witech Automation',
  'Worley Parson',
  'Wunderman',
  'Xansa Technologies',
  'Xs Real Properties',
  'Y Media Labs',
  'Yalamanchili Software Exports',
  'Yamaha Motors',
  'Yasu Technologies',
  'Yatra.Com',
  'You Telecom',
  'Young Buhmwoo Company',
  'Yukogawa',
  'Z E- Technologies',
  'Zebrnoics',
  'Zelos Technologies',
  'Zenith Software',
  'Zeotap',
  'Zilogic System',
  'Zolostays Property Solutions',
  'Zoominfo',
  'Zooppa',
  'Zozi',
  'Zuari Cement',
  'Zuti Technologies',
  'Zydus Cadila',
];

// Logical constants
export const whitelistedLeadSources = [
  'google-ads-search',
  'google-ads-youtube',
  'google-ads-discovery',
  'google-ads-display',
  'google-ads-performance-max',
  'ig',
  'fb',
  'linkedin-inmail',
  'linkedin-leadform',
  'email-reactivation',
  'email-retargeting',
  'criodo-blog',
  'adwords',
  'affiliate',
  'Affiliate',
  'social-instagram',
  'social-facebook',
  'social-linkedin',
  'social-twitter',
  'social-youtube',
  'whatsapp-reactivation',
  'whatsapp-campaign',
  'whatsapp-retargeting',
  'sms',
  'direct',
  'web-push',
  'user_referral',
  'bing',
  'Quora-Ads',
  'taboola',
  'affiliate-perf-ads',
  'Reddit-Ads',
  'google-ads-demand-gen',
  'valueleaf',
  'website',
];

export const ERROR_LEAD_SOURCE = 'error';

export const cleanSpecialCharacters = (str) =>
  str.replace(/([^\w]+|\s+)/g, '').toLowerCase();

export const generateDropDownValuesFromObject = (map) => {
  const dropDownValues = [];
  for (const [key, value] of Object.entries(map)) {
    dropDownValues.push({ value: key, label: value });
  }
  return dropDownValues;
};

export const generateDropDownValues = (ar) => {
  const dropDownValues = [];
  for (let idx = 0; idx < ar.length; idx++)
    dropDownValues.push({ value: ar[idx], label: ar[idx] });
  return dropDownValues;
};

export const extendLeadObjectWithUserLocationData = (
  leadDataObject,
  userLocationDataObject,
) => {
  if (!leadDataObject || !userLocationDataObject) return;

  const locationObjectToLeadMap = {
    country_name: 'country',
    city: 'city',
    state: 'state',
  };

  for (const [key, value] of Object.entries(locationObjectToLeadMap)) {
    if (key in userLocationDataObject)
      if (userLocationDataObject[key] != null) {
        leadDataObject[value] = userLocationDataObject[key];
      }
  }
};

// Generates graduation years' list from currentYear-30 to currentYear+10
export const getGraduationYearList = () => {
  const currentYear = new Date().getFullYear();
  const graduationData = [];
  for (let year = currentYear + 4; year >= currentYear - 22; year--)
    graduationData.push({ value: year, label: year });
  return graduationData;
};

// Converts date string to date of month string; example "2022-07-30" to 30th July
export const convertDateStringToDateOfMonth = (date) => {
  const fullDate = date.split('-');
  const year = Number(fullDate[0]);
  const month = Number(fullDate[1]) - 1;
  const day = Number(fullDate[2]);
  return format(new Date(year, month, day), 'do MMMM');
};

// Hash function - functionality similar to that of JAVA's hash function
export const stringHash = (str) => {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

// Returns experiment ID based on email hash;
// Current distribution => DEMO_FLOW = 100 & TC_FLOW = 0
export const allotExperimentId = (email) => {
  /*
    Commented code to make demo flow 100%  

    const hash = Math.abs(stringHash(email));
    const value = hash % 100;

    if (value < 65) {
      return experiments.DEMO_FLOW;
    } else {
      return experiments.TC_FLOW;
    }
  */
  return experiments.DEMO_FLOW;
};

// Does a 50/50 split for A/B test
export const shouldShowCompanyInput = (email) => {
  const hash = Math.abs(stringHash(email));
  const value = hash % 2;

  if (value) return true;
  return false;
};

// Get 2 next demo slots
// Sort all demo slots by date-time; Remove any sessions which are in past with a buffer of 30 minutes; finally get the 2 newest sessions
export const getTwoNextDemoSlots = (demoSlots) =>
  sortBy(demoSlots, (slot) =>
    slot?.TrialTime ? new Date(slot.TrialTime) : new Date(),
  )
    .filter(
      (slot) =>
        !isPast(
          sub(new Date(slot.TrialTime), {
            minutes: 30,
          }),
        ),
    )
    .slice(0, 2);

// Convert demo slots to menu items
export const getDemoSlotsMenuItems = (demoSlots) =>
  getTwoNextDemoSlots(demoSlots).reduce((acc, cur) => {
    const currentDemoSessionDate = new Date(cur.TrialTime);
    const formattedDemoSessionDate = format(
      currentDemoSessionDate,
      'EEE, do LLL h:mm aa',
    );
    let dateKey;

    if (isToday(currentDemoSessionDate))
      dateKey = `Today - ${formattedDemoSessionDate}`;
    else if (isTomorrow(currentDemoSessionDate))
      dateKey = `Tomorrow - ${formattedDemoSessionDate}`;
    else dateKey = formattedDemoSessionDate;

    acc.push({
      label: dateKey,
      value: cur.TrialTime,
    });
    return acc;
  }, []);

// experiment types
export const experiments = {
  KICKOFF_FLOW: 'KICKOFF_FLOW',
  DEMO_FLOW: 'DEMO_FLOW',
  TC_FLOW: 'TC_FLOW',
  REBOOT_FLOW: 'REBOOT_FLOW',
  MASTERCLASS_FLOW: 'MASTERCLASS_FLOW',
};

// trial types
export const trialTypes = {
  MQA_TRIAL: 'MQA_TRIAL',
  FELLOWSHIP_PLUS: 'FELLOWSHIP_PLUS',
  FELLOWSHIP_TRIAL: 'FELLOWSHIP_TRIAL',
  ACCELERATE: 'ACCELERATE',
  DATA_SCIENCE: 'DATA_SCIENCE',
};

//LSQ Profile constants:
export const LSQ_PROFILE = {
  WORKING_PROFESSIONAL: 'Working Professional',
  STUDENT: 'Student',
  NON_WORKING_PROFESSIONAL: 'Non-Working Professional',
};

// API constants
export const LEAD_GEN_DEV_API_BASE_URL =
  'https://kb0bjzehw5.execute-api.ap-south-1.amazonaws.com/dev/firebase';
export const LEAD_GEN_PROD_API_BASE_URL =
  'https://xj2v7rkrpk.execute-api.ap-south-1.amazonaws.com/production/firebase';

// LeadStage constants
export const leadStage = {
  HALF_FILL: 'HalfFill',
  FULL_FILL: 'FullFill',
  OTHER_LEAD: 'OtherLead',
};

// UTM constants
export const LEAD_GEN_UTM_SOURCE_QA = 'QA_Automation';
export const LEAD_GEN_UTM_SOURCE_DEV = 'Software_Dev';
export const LEAD_GEN_UTM_MEDIUM = 'Platform';
export const LEAD_GEN_UTM_CAMPAIGN_ONE = 'Lead_Form_1';
export const LEAD_GEN_UTM_CAMPAIGN_TWO = 'Lead_Form_2';

// Program IDs
export const LEAD_GEN_PROGRAM_ID_FULL_STACK = 'FULL_STACK';
export const LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS = 'FELLOWSHIP_PLUS';
export const LEAD_GEN_PROGRAM_ID_QA = 'QA_AUTOMATION_TESTING';
export const LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS = 'DATA_ANALYTICS';
export const LEAD_GEN_PROGRAM_ID_DATA_SCIENCE = 'DATA_SCIENCE';

// Device Type
export const LEAD_GEN_DEVICE_MOBILE = 'Mobile';
export const LEAD_GEN_DEVICE_DESKTOP = 'Desktop';

// Data Source
export const LEAD_GEN_DATA_SOURCE = 'DATA_SOURCE_LEAD_FORM';

// Fallback values
export const LEAD_GEN_LOCATION_FALLBACK = 'Lead_Gen_Platform';
export const LEAD_GEN_PATHNAME_FALLBACK = '/';

// Button Location tags
export const LEAD_GEN_HERO = 'HERO';
export const LEAD_GEN_WORK_EXPERIENCE = 'WORK_EXPERIENCE';
export const LEAD_GEN_HOME_PROJECTS = 'HOME_PROJECTS';
export const LEAD_GEN_FLOATING_CTA = 'FLOATING_CTA';
export const LEAD_GEN_PLACEMENTS = 'PLACEMENTS';
export const LEAD_GEN_WHY_CRIO = 'WHY_CRIO';
export const LEAD_GEN_PROJECTS = 'PROJECTS';
export const LEAD_GEN_PLAN_SECTION = 'PLAN_SECTION';
export const LEAD_GEN_CURRICULUM = 'CURRICULUM';
export const LEAD_GEN_TECHNOLOGIES = 'TECHNOLOGIES';
export const LEAD_GEN_CAREER_ASSISTANCE = 'CAREER_ASSISTANCE';
export const LEAD_GEN_TESTIMONIALS = 'TESTIMONIALS';
export const LEAD_GEN_CRIO_COMPARISION = 'CRIO_COMPARISION';
export const LEAD_GEN_FAQ = 'FAQ';
export const LEAD_GEN_NAVIGATION = 'NAVIGATION';
export const LEAD_GEN_PROGRAM_CARD = 'PROGRAM_CARD';
export const LEAD_GEN_REDEEM_PROGRAM_CARD = 'REDEEM_PROGRAM_CARD';
export const LEAD_GEN_CURRICULUM_PRICING_CARD = 'CURRICULUM_PRICING_CARD';
export const LEAD_GEN_DEVELOPERS_CRACKED = 'DEVELOPERS_CRACKED';
export const LEAD_GEN_PROGRAM_STEPS = 'PROGRAM_STEPS';
export const LEAD_GEN_COMPANIES = 'COMPANIES';
export const LEAD_GEN_CAREER_SERVICES = 'CAREER_SERVICES';
export const LEAD_GEN_PLACEMENTS_COMPANIES = 'PLACEMENTS_COMPANIES';
export const LEAD_GEN_LEARNER_STATS = 'LEARNER_STATS';
export const LEAD_GEN_COMPENSATION = 'COMPENSATION';
export const LEAD_GEN_PRE_FOOTER = 'PRE_FOOTER';
export const LEAD_GEN_HIRING_SECTION = 'HIRING_SECTION';
export const LEAD_GEN_TEXTUAL_SECTION = 'TEXTUAL_SECTION';
export const LEAD_GEN_FORM_OPEN = 'FORM_OPEN_USING_QUERY_PARAM';
export const LEAD_GEN_AD_PAGE = 'AD_PAGE';
export const LEAD_GEN_SALE_POPUP = 'SALE_POPUP';
export const LEAD_GEN_PROMO_POPUP = 'PROMO_POPUP';
export const LEAD_GEN_AFFILIATE_REDEEM = 'AFFILIATE_REDEEM';
export const LEAD_GEN_DOWNLOAD_PLACEMENT_STATS = 'Download_Placement_Stats';
export const LEAD_GEN_DOWNLOAD_CURRICULUM = 'Download_Curriculum';
export const LEAD_GEN_CHATBOT = 'CHATBOT';
export const LEAD_GEN_CHATBOT_CALLBACK = 'CHATBOT_CALLBACK';
export const LEAD_GEN_BOTTOM_STRIP_CHATBOT_CALLBACK =
  'BOTTOM_STRIP_CHATBOT_CALLBACK';
export const LEAD_GEN_MASTERCLASS_POST_REGISTRATION =
  'MASTERCLASS_POST_REGISTRATION';
export const LEAD_GEN_EMAIL_CAPTURE_POPUP = 'EMAIL_CAPTURE_POPUP';
export const LEAD_GEN_PROJECTS_PAGE_DOWNLOAD = 'PROJECTS_PAGE_DOWNLOAD';
export const LEAD_GEN_PROJECTS_PAGE_NOTIFY = 'PROJECTS_PAGE_NOTIFY';
export const LEAD_GEN_ROI_SALARY_VISUALIZATION = 'ROI_SALARY_VISUALIZATION';
export const LEAD_GEN_MENTOR_SECTION = 'MENTOR_SECTION';

//Path name:
export const LEAD_GEN_QA_AUTOMATION_PATH = '/qa-automation-testing/';
export const LEAD_GEN_QA_AUTOMATION_PPC_PATH =
  '/programs/qa-automation-testing/';
export const LEAD_GEN_AUTOMATION_EDGE_PATH = '/automation-edge/';

//TRIAL SESION OPTION:
export const OTHER_DEMO_SLOT = 'OTHER_DEMO_SLOT';

export const EXTERNAL_DEMO_SLOT_SELECTION_PORTAL =
  'https://event.webinarjam.com/register/8/18o08uq';

export const routesWithCustomDemoSlots = [
  '/',
  EPathname.SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM,
  EPathname.SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM_PPC_PAGE,
  EPathname.ACCELERATE,
  EPathname.SYSTEM_DESIGN,
  EPathname.QA_AUTOMATION_TESTING,
  EPathname.QA_PPC_PAGE,
  EPathname.DATA_ANALYTICS,
  EPathname.DATA_ANALYTICS_PPC_PAGE,
  EPathname.CAMPAIGN,
  EPathname.ON_DEMAND_AI_DATA_ANALYTICS,
  EPathname.ON_DEMAND_QA_AUTOMATION_TESTING,
  EPathname.ON_DEMAND_SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM,
];

export const CWP_EVENT_FIRED_KEY = 'cwp_event_fired';
